.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  z-index: 9999;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .rightContainer {
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    a {
      color: var(--primary);
    }
  }

  .linksContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(57, 62, 70, 0.8);
    backdrop-filter: blur(3px);
    z-index: -1;
  }
}

.logo {
  padding: 0 2em;
  width: 4rem;

  span {
    color: #fff;
    font-size: 1.6em;
    font-weight: bold;
    font-family: "Inconsolata", monospace;
    text-transform: uppercase;
    max-width: 0.99em;
    overflow-x: hidden;
    position: absolute;
    transition: all 200ms ease-in-out;
  }

  span:last-child {
    transform: translateX(1em);
  }

  &:hover {
    span {
      color: var(--primary);
      max-width: 100px;
    }

    span:first-child {
      transform: translateY(-0.5rem) scale(0.85);
    }

    span:last-child {
      transform: translateY(0.5rem) translateX(-0.25em) scale(0.85);
    }
  }
}

.sideLink {
  display: block !important;
  text-align: center;
  margin-bottom: 1.7rem;
  font-family: "Inconsolata", monospace;
  font-size: 1.2rem;

  a {
    white-space: pre;
    display: block !important;
  }
}

.language {
  margin-bottom: .2rem;
  cursor: pointer;
  color: #d1d1d1;
  margin-right: 1rem;
}

.navLink,
.language {
  font-family: "Inconsolata", monospace;
  display: block;
  transition: color 150ms ease-in-out;

  &:hover {
    color: var(--primary) !important;
    transition: color 150ms ease-in-out;
  }
}

.navLink {
  margin-right: 1rem;
}

@for $i from 1 through 4 {
  .sideLink:nth-child(#{$i}) {
    a::before {
      content: "0" + $i + ":\A";
      color: var(--primary);
      line-height: 2rem;
      font-size: 1.1rem;
    }
  }

  .navLink:nth-child(#{$i})::before {
    content: "0" + $i + ":";
    color: var(--primary);
    margin-right: 0.3rem;
  }
}

.sidebar {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% + 60px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s;
  pointer-events: none;
  visibility: hidden;

  >.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }
}

.sidebar.hidden {
  .sidebarContent {
    max-width: 0;
  }

  .backdrop {
    background-color: transparent;
    visibility: hidden;
  }
}

.sidebarContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: min(70vw, 300px);
  margin-left: auto;
  height: 100%;
  background-color: rgb(57, 62, 70);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  pointer-events: all;
  position: relative;

  .resumeContainer {
    position: absolute;
    bottom: calc(60px + 1rem);
    height: fit-content;

    a {
      color: var(--primary);
    }
  }

  .languageContainer {
    position: absolute;
    top: 1.5rem;

    .language {
      margin-right: 0;
    }
  }
}

.hamburgerContainer {
  position: absolute;
  right: 1rem;
  top: 9px;
  z-index: 1;
  pointer-events: all;

  .icon {
    width: 20px;
  }

  .icon:before,
  .icon:after,
  .icon div {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    border-radius: 3px;
    margin: 5px 0;
    transition: 0.5s;
  }

  .icon.close:before {
    transform: translateY(7px) rotate(135deg);
  }

  .icon.close:after {
    transform: translateY(-7px) rotate(-135deg);
  }

  .icon.close div {
    transform: scale(0);
  }
}

@media only screen and (max-width: 600px) {

  .navbar .navLink,
  .navbar>.rightContainer {
    display: none;
    max-width: 0;
  }

  .hamburgerContainer {
    display: flex;
    max-width: 150px;
    margin-right: 0.75rem;
  }

  .sidebar {
    visibility: visible;
  }
}