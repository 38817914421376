.about-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about {
  width: 60%;
  max-width: 1000px;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    padding-top: 4rem;
  }

  .text-container {
    padding: 1rem;

    .technologies-container {
      color: #fff;
    }
  }

  .img-container {
    position: relative;
    padding: 0;

    img {
      width: 100%;
      object-fit: cover;
      border: var(--primary) solid 2px;
      border-radius: 13px;
    }

    .underlay,
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: -5px;
      bottom: 5px;
      background-color: var(--primary);
      border-radius: 13px;
    }

    .underlay {
      z-index: -1;
      opacity: 0.2;
    }

    .overlay {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    &:hover {
      .overlay {
        opacity: 0.2;
        transition: opacity 300ms ease-in-out;
      }
    }
  }
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.skill-chip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem 0.5rem;
  transition: all 300ms ease-in-out;
  opacity: 0.9;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    margin-right: 0.3rem;
    color: var(--primary);
  }

  .skill-icon {
    color: #fff;
    display: hidden;
    max-width: 0;
    transition: all 300ms ease-in-out;
    margin-left: auto;
  }
}

.text-container p {
  font-size: 1.1rem;
}

.text-container .btn-show-more {
  width: 100%;
  border-radius: 25px;
  margin-top: 1rem;
}

.job-text {
  color: var(--primary);
  filter: saturate(0.4);
}

@media screen and (max-width: 600px) {
  .about-wrapper {
    display: block;
  }

  #about {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
  }

  #about > div:last-child {
    padding-top: 1rem;
  }

  .img-container {
    width: 70%;
  }
}
