$link-hover-transition: all 0.2s ease-in-out;
$links-height: 50px;

.projects-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#projects {
  width: 50%;
  max-width: 1000px;
}

.project-item {
  min-height: 100px;
  position: relative;
  height: 100%;
  padding-bottom: 0.5rem;
  overflow: hidden;
}

.project-links svg {
  transform: scale(1.3);
  padding: 0.2rem;
}

.project-item:hover > .content {
  transform: translateY(-45px);
  transition: $link-hover-transition;
}

.project-item .links-container {
  height: 2px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.04);
  width: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: $link-hover-transition;

  .links {
    position: absolute;
    width: 100%;
    height: $links-height;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.project-item > .content {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: $link-hover-transition;

  h3 {
    margin: 0;
  }

  p {
    flex-grow: 1;
  }

  .technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;
    color: var(--primary);
    margin-top: auto;
    font-family: "Inconsolata", monospace;

    span {
      margin: 0 0.5rem;
    }
  }
}

.project-item:hover .links-container {
  width: 100%;
  height: $links-height;
  margin: 0;
  border-radius: 0 0 2px 2px;
  transition: all 0.2s ease-in-out;
}

.no-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;

  img {
    width: 100px;
    object-fit: contain;
    opacity: 0.5;
    user-select: none;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    width: 100%;
    text-align: center;
  }
}

#projects > .grid-container {
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  #projects {
    width: 100%;
    margin: 0 1rem;
  }
}
