#jobInstructionsOverlay {
  display: block;
}

#jobInstructionsOverlay.hidden {
  display: none;
}

#jobInstructionsOverlay > .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: contrast(40%) brightness(25%);
  z-index: 10000;
  overflow: hidden;
}

#jobInstructionsBox p {
  margin: 0;
  color: #fff;
}

#jobInstructionsBox {
  position: absolute;
  z-index: 10001;
  max-width: 550px;
  background-color: #35383b;
  padding: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 3px;
  display: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.6rem;
}
