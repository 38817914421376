.container {
  display: flex;
  margin: 0 1rem;
}

.introTextContainer {
  flex-grow: 1;
  display: flex;
  padding-left: 5%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  max-width: 40vw;

  .greeting {
    font-size: 1.1rem;
    font-family: "Inconsolata", monospace;
  }

  h1 {
    margin: 0;
    font-size: 4rem;
    color: var(--primary);

    &::after {
      content: ',';
      color: #fff;
    }
  }

  h2 {
    margin: 0;
    color: #c8c9cb;
    font-size: 2.2rem;
  }

  p {
    font-size: 1.1rem;
    width: 35vw;
    max-width: 600px;
  }
}

.wordsImage {
  right: 0;
  opacity: 0.12;
  top: 0;
  position: absolute;
  height: 100vh;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .wordsImage {
    opacity: 0.015;
  }

  .introTextContainer {
    max-width: 100%;

    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    p {
      width: 100%;
      max-width: 100%;
    }
  }
}
