.contactWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact {
  width: 50%;
  max-width: 800px;
  padding-bottom: 75px;
}

@media screen and (max-width: 600px) {
  .contact {
    width: 100%;
    margin: 0 1rem;
  }
}
