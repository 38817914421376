@keyframes ZoomIn {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

$tab-transition: 0.3s ease-in-out;

.experience-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#experience {
  width: 50%;
  max-width: 800px;
}

#experience .content {
  display: flex;
  margin-top: 1rem;

  .text-container {
    width: 80%;
    flex-grow: 1;
    animation: 0.2s ease-out 0s 1 ZoomIn;

    h3 {
      margin: 0;
      max-width: 400px;
      color: #d0d2d5;
      font-weight: 700;
    }

    .period {
      margin-top: 0.5rem !important;
      font-size: 1rem;
      font-family: "Inconsolata", monospace;
      color: var(--primary);
      margin: 0;
    }

    p::before {
      content: "⋄";
      margin-right: 0.5rem;
      color: var(--primary);
    }

    p > b, strong {
      color: #d0d2d5;
    }

    .mobile-company {
      display: none;
      color: var(--primary);
      margin-bottom: 0.3rem;
      font-size: 0.8em;
    }
  }
}

#experience .tabs {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  .tab {
    padding: 0.5rem;
    color: #a8aaad;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    min-width: 125px;
    user-select: none;
    transition: all $tab-transition;
    border-right: 2px solid rgba(255, 255, 255, 0.2);

    svg {
      margin-right: 0;
      max-width: 0;
      transition: all $tab-transition;
    }
  }

  .tab.active,
  .tab:hover {
    color: #fff;
    font-weight: 600;
    transition: all $tab-transition;

    svg {
      margin-right: 0.5rem;
      max-width: 50px;
      transition: all $tab-transition;
    }
  }

  .tab.tab.active {
    background-color: #343941;
    border-right: 2px solid var(--primary);
    transition: all $tab-transition;

    svg {
      fill: var(--primary);
    }
  }
}

@media screen and (max-width: 600px) {
  #experience {
    width: 100%;
    margin: 0 1rem;
  }

  .tab {
    min-width: 15px !important;

    svg {
      margin-right: 0 !important;
      max-width: 50px !important;
    }

    span {
      width: 0;
      display: none;
    }
  }

  .mobile-company {
    display: block !important;
  }
}
