:root {
  --second-text-color: #a8aaad;
  --disabled-text-color: #7d7e81;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: max(10%, 60px);
}

body {
  background-color: #232931;
  color: #fff;
}

* {
  -webkit-tap-highlight-color: #767a8136;
}

::selection {
  background: #474e58;
}

p {
  color: var(--second-text-color);

  b, strong {
    color: #fff;
  }

  a, a:visited {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: var(--primary);
  }
  
  a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.w-100 {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

section {
  padding: 100px 0;

  .section-title {
    font-size: 1.7rem;
    margin: 0;
    margin-top: 0.5rem;

    span {
      color: var(--primary);
      font-family: "Inconsolata", monospace;
      font-size: 0.9em;
    }
  }
}

.job-title {
  cursor: pointer;
  color: var(--disabled-text-color);
  transition: all 150ms ease-in-out;

  &:hover,
  &.active {
    color: var(--primary);
    transition: all 150ms ease-in-out;
  }
}

a.underline {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: var(--primary);
}

a.underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 1px;
  left: 0;
  background-color: var(--primary);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a.underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #232931;
  -webkit-box-shadow: 0 0 0 30px #393d46 inset !important;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #595f69;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

